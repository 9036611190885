<template>
    <v-dialog
            persistent
            v-model="editDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ updateMode == false ? $t('message.addSalesBudget') : $t('message.updateSalesBudget') + ": " + salesBudget }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="salesBudgetForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.monthYear') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-row no-gutters>
                                <v-col>{{ $t('message.year') + ': ' + getYear(SalesBudget__contract_date) }}</v-col>
                                <v-col>{{ $t('message.month') + ': ' + getMonth(SalesBudget__contract_date) }}</v-col>
                            </v-row>
                            <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    max-width="290px"
                                    min-width="290px"
                                    ref="ncrPreferredEtdMenu"
                                    transition="scale-transition"
                                    v-model="salesBudgetDateMenu"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            :label="SalesBudget__contract_date ? '' : $t('message.required')"
                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                            :value="computedSalesBudgetDateFormatted"
                                            append-icon="event"
                                            clearable
                                            class="force-text-left"
                                            hint="DD/MM/YY format"
                                            hide-details="auto"
                                            background-color="white"
                                            persistent-hint
                                            solo
                                            v-on="on"
                                            @click:clear="SalesBudget__contract_date = null"
                                            @click:append="salesBudgetDateMenu = true"
                                    />
                                </template>
                                <v-date-picker
                                        first-day-of-week="1"
                                        locale-first-day-of-year="4"
                                        show-week
                                        v-model="SalesBudget__contract_date"
                                        @input="salesBudgetDateMenu = false"
                                />
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesGroup') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="salesGroups"
                                    :rules="[...validationRules.required]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="SalesBudget__destination_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesColleague') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="allSalesColleagues"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Salesperson.name"
                                    item-value="Salesperson.id"
                                    solo
                                    v-model="SalesBudget__salescontact_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesType') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="salesTypes"
                                    :rules="[...validationRules.required]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="SalesBudget__salestype_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesAmount') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :min="0"
                                    :rules="[...validationRules.required, ...validationRules.amount]"
                                    :value="SalesBudget__total_intake_sales"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    type="number"
                                    @change="SalesBudget__total_intake_sales = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.marginAmount') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :min="0"
                                    :rules="[...validationRules.required, ...validationRules.amount]"
                                    :value="SalesBudget__total_intake_margin"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    type="number"
                                    @change="SalesBudget__total_intake_margin = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addSalesBudget()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveSalesBudget()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {formatDate, log} from "Helpers/helpers";
import {validationRules} from "@/store/modules/appic/constants";

export default {
    name: "AddSalesBudget",
    props: ['salesBudgetId','salesBudget','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            // rules: {
            //     amount: v => !!v || this.$t('message.required'),
            //     date: v => !!v || this.$t('message.required'),
            //     salesColleague: v => !!v || this.$t('message.required'),
            //     salesGroup: v => !!v || this.$t('message.required'),
            //     salesType: v => !!v || this.$t('message.required')
            // },
            salesBudgetDateMenu: false,
            salesGroups: [
                {value: 1, text: 'Malaysia'},
                {value: 2, text: 'Thailand'},
                {value: 3, text: 'Vietnam'},
                {value: 4, text: 'Indonesia'},
                {value: 5, text: 'China'},
                {value: 998, text: 'Export'},
                {value: 999, text: 'Others'}
            ],
            salesTypes: [
                {value: 1, text: 'DS/SS'},
                {value: 2, text: 'CS'},
                {value: 99, text: 'WH'},
            ],
            validForm: true
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('salesbudget',{
            SalesBudget__id: 'SalesBudget.id',
            SalesBudget__contract_date: 'SalesBudget.contract_date',
            SalesBudget__destination_id: 'SalesBudget.destination_id',
            SalesBudget__salestype_id: 'SalesBudget.salestype_id',
            SalesBudget__salescontact_id: 'SalesBudget.salescontact_id',
            SalesBudget__total_intake_sales: 'SalesBudget.total_intake_sales',
            SalesBudget__total_intake_margin: 'SalesBudget.total_intake_margin',
        },'statePrefix'),
        ...mapGetters('salescolleague',{
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters([
            'months',
            'validationRules'
        ]),
        computedSalesBudgetDateFormatted() {
            return formatDate(this.SalesBudget__contract_date)
        },
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('salesbudget',{
            createSalesBudget: 'createSalesBudget',
            getSalesBudgetToUpdateById: 'getSalesBudgetToUpdateById',
            resetSalesBudgetToUpdate: 'resetSalesBudgetToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateSalesBudget: 'updateSalesBudget'
        }),
        ...mapActions('salescolleague', {
            getAllSalesColleagues: 'getAllSalesColleagues',
        }),
        addSalesBudget () {
            if(this.$refs.salesBudgetForm.validate()) {
                this.loading.add = true
                this.createSalesBudget()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.salesBudgetAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.salesBudgetNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.salesBudgetNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.salesBudgetNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        getYear(date) {
            if(date) {
                return date.substring(0, 4)
            }
            return ''
        },
        getMonth(date) {
            if(date) {
                let monthNum = date.substring(5, 7)
                monthNum = parseInt(monthNum)
                let month = this.months.find(m => m.Month.id == monthNum)
                if (month) {
                    return month.Month.text
                }
            }
            return ''
        },
        saveSalesBudget () {
            if(this.$refs.salesBudgetForm.validate()) {
                this.loading.save = true
                this.updateSalesBudget()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.salesBudgetUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.salesBudgetNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.salesBudgetNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.salesBudgetNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getSalesBudgetToUpdateById(this.salesBudgetId)
                        .then( () => {
                            this.loading.get = false
                        })
                }
            } else {
                if(this.updateMode == true) {
                    this.resetSalesBudgetToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
    }
}
</script>

<style scoped>

</style>